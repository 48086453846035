import React from 'react';
import Sliders02 from '../../containers/SliderContainers/Sliders02';

const Home02 = () => {
    return (
        <div className="wrapper">
            <Sliders02 />
        </div>
    );
};

export default Home02;